import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useRecoilValue } from 'recoil';
import { selectedForComparison } from '../../state/selectedForComparison';

const PersistenceObserver = () => {
  const partnerships = useRecoilValue(selectedForComparison);

  useEffect(() => {
    Cookies.set('selectedForComparison', JSON.stringify(partnerships), {
      expires: 0.5, // Half a day (12 hours) https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#expire-cookies-in-less-than-a-day
    });
  }, [partnerships]);

  return null;
};

export default PersistenceObserver;
