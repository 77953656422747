'use strict';

const sectors = {
  PO: 'primair-onderwijs',
  VO: 'voortgezet-onderwijs',
};

const graphYearFormat = [
  '_2019_2020',
  '_2018_2019',
  '_2017_2018',
  '_2016_2017',
  '_2015_2016',
];

const participationRateFragment = `
PRO
LWOO
VSO
SBO
SO
`;

const equityFragment = `
publicEquity
signalValue
excessiveReserve
excessiveReservePercent
`;

const studentCountFragment = `
BO
SBO
SO__bekostigd_
PRO
VO
VSO__bekostigd_
`;

const reservePositionFragment = `
calculatedResistance
equity
governmentGrants
materialFixedAssets
resistance
`;

const signalingValueFragment = `
publicEquity
signalValue
netGain
excessiveReservePercent
excessiveReserve
`;

const supportBudgetFragment = `
  governmentGrants,
  otherGovernmentGrants,
  tutionFeeCharges,
  thirdPartyBenefits,
  otherBenefits,
  totalBenefits,
  personelCharges,
  depreciations,
  housingCharges,
  otherCharges,
  throughPayments,
  subsidies,
  totalCharges,
  totalDifference,
`;

const nationalStudentCountFragment = `
  name
  sector
  slug
  studentCount { ${studentCountFragment} }
`;

const nationalAbsenceFragment = `
immunity
longLastingRelativeAbsence
moreThanThreeAbsoluteAbsence
moreThanThreeLongLastingRelativeAbsence
totalAbsoluteAbsence
`;

module.exports = {
  sectors,
  graphYearFormat,
  equityFragment,
  participationRateFragment,
  studentCountFragment,
  nationalStudentCountFragment,
  nationalAbsenceFragment,
  reservePositionFragment,
  signalingValueFragment,
  supportBudgetFragment,
};
