// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-encyclopedie-begrippen-js": () => import("./../../../src/pages/encyclopedie/begrippen.js" /* webpackChunkName: "component---src-pages-encyclopedie-begrippen-js" */),
  "component---src-pages-encyclopedie-index-js": () => import("./../../../src/pages/encyclopedie/index.js" /* webpackChunkName: "component---src-pages-encyclopedie-index-js" */),
  "component---src-pages-encyclopedie-uitleg-indicatoren-js": () => import("./../../../src/pages/encyclopedie/uitleg-indicatoren.js" /* webpackChunkName: "component---src-pages-encyclopedie-uitleg-indicatoren-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kaart-js": () => import("./../../../src/pages/kaart.js" /* webpackChunkName: "component---src-pages-kaart-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-vergelijken-financien-js": () => import("./../../../src/pages/vergelijken/financien.js" /* webpackChunkName: "component---src-pages-vergelijken-financien-js" */),
  "component---src-pages-vergelijken-index-js": () => import("./../../../src/pages/vergelijken/index.js" /* webpackChunkName: "component---src-pages-vergelijken-index-js" */),
  "component---src-pages-vergelijken-leerlingen-js": () => import("./../../../src/pages/vergelijken/leerlingen.js" /* webpackChunkName: "component---src-pages-vergelijken-leerlingen-js" */),
  "component---src-pages-vergelijken-organisatie-js": () => import("./../../../src/pages/vergelijken/organisatie.js" /* webpackChunkName: "component---src-pages-vergelijken-organisatie-js" */),
  "component---src-templates-modular-page-index-js": () => import("./../../../src/templates/ModularPage/index.js" /* webpackChunkName: "component---src-templates-modular-page-index-js" */),
  "component---src-templates-national-financial-index-js": () => import("./../../../src/templates/National/Financial/index.js" /* webpackChunkName: "component---src-templates-national-financial-index-js" */),
  "component---src-templates-national-organization-index-js": () => import("./../../../src/templates/National/Organization/index.js" /* webpackChunkName: "component---src-templates-national-organization-index-js" */),
  "component---src-templates-national-start-index-js": () => import("./../../../src/templates/National/Start/index.js" /* webpackChunkName: "component---src-templates-national-start-index-js" */),
  "component---src-templates-national-students-index-js": () => import("./../../../src/templates/National/Students/index.js" /* webpackChunkName: "component---src-templates-national-students-index-js" */),
  "component---src-templates-partnership-downloads-index-js": () => import("./../../../src/templates/Partnership/Downloads/index.js" /* webpackChunkName: "component---src-templates-partnership-downloads-index-js" */),
  "component---src-templates-partnership-financial-index-js": () => import("./../../../src/templates/Partnership/Financial/index.js" /* webpackChunkName: "component---src-templates-partnership-financial-index-js" */),
  "component---src-templates-partnership-organization-index-js": () => import("./../../../src/templates/Partnership/Organization/index.js" /* webpackChunkName: "component---src-templates-partnership-organization-index-js" */),
  "component---src-templates-partnership-start-index-js": () => import("./../../../src/templates/Partnership/Start/index.js" /* webpackChunkName: "component---src-templates-partnership-start-index-js" */),
  "component---src-templates-partnership-students-index-js": () => import("./../../../src/templates/Partnership/Students/index.js" /* webpackChunkName: "component---src-templates-partnership-students-index-js" */)
}

