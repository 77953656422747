import { atom, selector, selectorFamily } from 'recoil';
import { find, propEq, map, pick } from 'ramda';
import notNil from '../../utils/notNil';
import { sectors } from '../../../backend/constants';

const { GATSBY_SERVERLESS_ENTRY = '/' } = process.env;

const _cache = {};

// The state
export const selectedForComparison = atom({
  key: 'selectedForComparison',
  default: [],
});

// Returns a shallow preview version of partnerships
export const previewState = selector({
  key: 'previewState',
  get: ({ get }) => {
    const partnerships = get(selectedForComparison);
    return map(
      partnership => ({
        regionSource: `/images/regions/${sectors[partnership.sector]}/${
          partnership.externalId
        }.svg`,
        ...pick(
          [
            'externalId',
            'sector',
            'name',
            'datoAddress',
            'datoAddressHousenumber',
            'datoAddressAddition',
            'datoZipcode',
            'datoCity',
            'datoWebsite',
            'datoSchools',
            'datoSchoolboards',
            'datoMunicipalities',
          ],
          partnership
        ),
      }),
      partnerships
    );
  },
});

export const withIndicators = selector({
  key: 'withIndicators',
  get: async ({ get }) => {
    const state = get(previewState);
    const promises = state.map(({ externalId }) => {
      if (_cache[externalId]) {
        return Promise.resolve(_cache[externalId]);
      }
      return fetch(
        `${GATSBY_SERVERLESS_ENTRY}.netlify/functions/getPartnership?externalId=${externalId}`
      ).then(res => res.json());
    });

    const res = await Promise.all(promises);

    res.forEach(item => (_cache[item.externalId] = item));

    return state.map((x, i) => ({ ...x, ...res[i] }));
  },
});

// Used to check if a partnership is currently selected for comparison
export const isComparing = selectorFamily({
  key: 'isComparing',
  get:
    externalId =>
    ({ get }) => {
      if (!externalId) {
        return false;
      }
      const partnerships = get(selectedForComparison);
      const subject = find(propEq('externalId', externalId), partnerships);
      return notNil(subject);
    },
});

export const comparingSector = selector({
  key: 'comparingSector',
  get: ({ get }) => {
    const [{ sector } = { sector: null }] = get(selectedForComparison);
    if (sector === null) {
      return sector;
    }
    return sectors[sector];
  },
});

export const comparePartnershipCount = selector({
  key: 'partnershipCount',
  get: ({ get }) => {
    const partnerships = get(selectedForComparison);
    return partnerships.length;
  },
});
