import React from 'react';
import { RecoilRoot } from 'recoil';
import Cookies from 'js-cookie';
import PersistenceObserver from './src/components/PersistenceObserver';
import { selectedForComparison } from './src/state/selectedForComparison';

const initializeState = ({ set }) => {
  const partnerships = Cookies.getJSON('selectedForComparison') || [];
  set(selectedForComparison, partnerships);
};

const wrapRootElement = ({ element }) => (
  <RecoilRoot initializeState={initializeState}>
    <PersistenceObserver />
    {element}
  </RecoilRoot>
);

export default wrapRootElement;
